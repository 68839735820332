import React, { useState } from 'react';

const App = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [avatar, setAvatar] = useState(null); // State for avatar image
  const [signatureHTML, setSignatureHTML] = useState('');
  const [copyButtonVisible, setCopyButtonVisible] = useState(false);

  const handleAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result); // Set avatar as base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  const generateSignature = () => {

    const signature = `
      <table
  cellspacing="0"
  cellpadding="0"
  border="0"
  style="
    font-family: Arial, sans-serif;
    color: #000000;
    width: 100%;
    background: transparent !important;
  "
>
  <tbody>
    <tr>
      <td
        style="
          width: 105px;
          font-size: 10pt;
          padding-top: 15px;
          font-family: Arial, sans-serif;
          color: #000000;
          line-height: 12pt;
          padding-bottom: 10px;
          border-top: 1px solid #00008b;
          text-align: center;
          vertical-align: center;
        "
      >
        <p style="margin: 0">
          <img
            alt="avatar"
            border="0"
            style="
              max-width: 150px;
              width: 100px;
              height: 100px;
              border-radius: 50%;
              max-height: 100px;
            "
            src=${avatar || 'https://www.anzmigrate.com/images/ANZSquare.png'}
          /><br />
        </p>
      </td>
      <td
        style="width: 25px; padding-top: 15px; border-top: 1px solid #00008b"
      ></td>
      <td
        style="
          padding-top: 15px;
          font-family: Arial, sans-serif;
          line-height: 15px;
          padding-bottom: 0px;
          border-top: 1px solid #00008b;
          width: calc(100% - 130px);
          vertical-align: top;
        "
      >
        <span
          style="
            font-size: 12pt;
            color: #00008b;
            font-family: Arial, sans-serif;
          "
          ><strong>${name}</strong><br
        /></span>
        <span style="font-size: 9pt; color: #000000"
          >${title}<br /><br
        /></span>
        <span style="font-size: 9pt; color: #000000; line-height: 13pt">
          <a
            href="mailto:${email}"
            style="
              font-size: 9pt;
              color: #000000;
              line-height: 13pt;
              text-decoration: none;
            "
          >
            <span
              style="
                font-weight: 600;
                font-size: 9pt;
                color: #000000;
                line-height: 13pt;
                text-decoration: none;
              "
              >${email}</span
            >
          </a>
          <br />
        </span>
        <span style="font-size: 9pt; color: #000000; line-height: 13pt">
          <strong>Phone:</strong> +65 6100-2878</strong>
          
          
          ${phone && (
        `<br /> <strong>Direct Line:</strong> ${phone}`
      )}
          
          <br /> <br />
          <span style="font-size: 9pt; color: #000000; line-height: 13pt"
            >ANZ Migrate,</span
          >
          Singapore, Australia
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <table
          cellspacing="0"
          cellpadding="0"
          border="0"
          style="background: transparent !important"
        >
          <tbody>
            <tr>
              <td style="padding-right: 5px; height: 22px">
                <a
                  href="https://www.facebook.com/anzmigrate"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    border="0"
                    width="18"
                    height="18"
                    style="border: 0; height: 18px; width: 18px"
                    src="https://www.mail-signatures.com/signature-generator/img/templates/wind-in-your-sails/fb.png"
                  />
                </a>
              </td>
              <td style="padding-right: 5px">
                <a
                  href="https://twitter.com/anzmigrate"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    border="0"
                    width="18"
                    height="18"
                    style="border: 0; height: 18px; width: 18px"
                    src="https://www.mail-signatures.com/signature-generator/img/templates/wind-in-your-sails/tt.png"
                  />
                </a>
              </td>
              <td style="padding-right: 5px">
                <a
                  href="https://www.youtube.com/anzmigrate"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    border="0"
                    width="18"
                    height="18"
                    style="border: 0; height: 18px; width: 18px"
                    src="https://www.mail-signatures.com/signature-generator/img/templates/wind-in-your-sails/yt.png"
                  />
                </a>
              </td>
              <td style="padding-right: 5px">
                <a
                  href="https://www.linkedin.com/company/anzmigrate"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    border="0"
                    width="18"
                    height="18"
                    style="border: 0; height: 18px; width: 18px"
                    src="https://www.mail-signatures.com/signature-generator/img/templates/wind-in-your-sails/ln.png"
                  />
                </a>
              </td>
              <td style="padding-right: 5px">
                <a
                  href="https://www.instagram.com/anzmigrate"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    border="0"
                    width="18"
                    height="18"
                    style="border: 0; height: 18px; width: 18px"
                    src="https://www.mail-signatures.com/signature-generator/img/templates/wind-in-your-sails/it.png"
                  />
                </a>
              </td>
              ${whatsapp && ( // Conditionally render WhatsApp icon
        ` <td style={{ paddingRight: '5px' }}>
          <a href="${whatsapp}" target="_blank" rel="noopener">
        <img
              border = "0"
              width = "18"
              height = "18"
              style = {{ border: '0', height: '18px', width: '18px' }
  }
  src = "https://www.mail-signatures.com/signature-generator/img/templates/wind-in-your-sails/wa.png"
    />
          </a >
        </td > `
      )}
            </tr>
          </tbody>
        </table>
      </td>
      <td style="width: 20px"></td>
      <td
        valign="top"
        style="
          font-family: Arial, sans-serif;
          line-height: 15px;
          padding-bottom: 0px;
        "
      >
        <span>
          <a
            href="http://www.anzmigrate.com"
            target="_blank"
            rel="noopener"
            style="
              text-decoration: none;
              color: #00008b;
              font-family: Arial, sans-serif;
              font-size: 9pt;
            "
          >
            <strong>www.anzmigrate.com</strong>
          </a>
        </span>
      </td>
    </tr>
  </tbody>
</table>
`;

    setSignatureHTML(signature);
    setCopyButtonVisible(true);
  };


  const copySignature = () => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = signatureHTML;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    alert('Signature HTML copied to clipboard!');
  };

  return (
    <div style={{ maxWidth: '600px', margin: '20px auto', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center', color: '#111e54' }}>Email Signature Generator</h1>

      <div style={{ background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <div className="form-group" style={{ marginBottom: '1rem' }}>
          <label htmlFor="name" style={{ fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Full Name:</label>
          <input
            type="text"
            id="name"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </div>

        <div className="form-group" style={{ marginBottom: '1rem' }}>
          <label htmlFor="email" style={{ fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Email:</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </div>

        <div className="form-group" style={{ marginBottom: '1rem' }}>
          <label htmlFor="title" style={{ fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Title:</label>
          <input
            type="text"
            id="title"
            placeholder="Enter your title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </div>

        <div className="form-group" style={{ marginBottom: '1rem' }}>
          <label htmlFor="phone" style={{ fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Your Cell Phone Number (Optional):</label>
          <input
            type="text"
            id="phone"
            placeholder="Example: (65) 6100-1234"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </div>

        <div className="form-group" style={{ marginBottom: '1rem' }}>
          <label htmlFor="whatsapp" style={{ fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>WhatsApp URL (Optional):</label>
          <input
            type="text"
            id="whatsapp"
            placeholder="Leave empty if you do not wish to add a WhatsApp link"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </div>

        <div className="form-group" style={{ marginBottom: '1rem' }}>
          <label htmlFor="avatar" style={{ fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Profile Image: (can be left empty)</label>
          <input
            type="file"
            id="avatar"
            onChange={handleAvatarUpload}
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </div>

        <button
          type="button"
          onClick={generateSignature}
          style={{
            width: '100%',
            padding: '12px',
            borderRadius: '4px',
            backgroundColor: '#111e54',
            color: '#fff',
            border: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            marginTop: '10px'
          }}
        >
          Generate Signature
        </button>
      </div>

      <div
        className="signature-preview"
        style={{ border: '1px solid #ddd', padding: '15px', marginTop: '20px', borderRadius: '4px' }}
        dangerouslySetInnerHTML={{ __html: signatureHTML }}
      ></div>

      {copyButtonVisible && (
        <button
          id="copyButton"
          type="button"
          onClick={copySignature}
          style={{
            marginTop: '10px',
            padding: '12px',
            width: '100%',
            borderRadius: '4px',
            backgroundColor: '#1c2965',
            color: '#fff',
            fontSize: '16px',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          Copy HTML
        </button>
      )}
    </div>
  );
};

export default App;
